import React from 'react'
import { Link } from 'react-router-dom'

export default function Home() {
  return (
    <div>
           {/* ======= hero Section ======= */}
  <section id="hero">
    <div className="hero-container">
      <div id="heroCarousel" className="carousel slide carousel-fade" data-bs-ride="carousel" data-bs-interval="5000">
      {/* <ol id="hero-carousel-indicators" className="carousel-indicators">
          <li  data-target="#heroCarousel" data-slide-to="0"></li>
          <li data-target="#heroCarousel" data-slide-to="1"></li>
          <li data-target="#heroCarousel" data-slide-to="2"></li>
      </ol> */}
        {/* <ol className="carousel-indicators">
            <li className="active" data-target="#carouselExampleIndicators" data-slide-to="0"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
        </ol> */}

        <div className="carousel-inner" role="listbox">

          <div className="carousel-item active" style={{backgroundImage:`url('https://firebasestorage.googleapis.com/v0/b/edify-de3fa.appspot.com/o/crystal%2Fpowerlines.jpg?alt=media&token=7fea9578-712a-4af0-88de-9596e9bdf7d2')`}}>
            <div className="carousel-container">
              <div className="container">
                <h2 className="animate__animated animate__fadeInDown">Transmission and Distribution </h2>
                <p className="animate__animated animate__fadeInUp">Leading Provider Of Energy and Power Support Systems across Africa</p>
                <a href="#about" className="btn-get-started scrollto animate__animated animate__fadeInUp invisible">Get Started</a>
              </div>
            </div>
          </div>

          <div className="carousel-item" style={{backgroundImage:`url('https://firebasestorage.googleapis.com/v0/b/edify-de3fa.appspot.com/o/crystal%2Fconstruction.jpg?alt=media&token=1d2bef50-9c11-42d3-ad45-6599941ec9df')`}}>
            <div className="carousel-container">
              <div className="container">
                <h2 className="animate__animated animate__fadeInDown">Civil Engineering Works</h2>
                <p className="animate__animated animate__fadeInUp">Civil construction, erection, testing and commissioning of substations</p>
                <a href="#about" className="btn-get-started scrollto animate__animated animate__fadeInUp invisible">Get Started</a>
              </div>
            </div>
          </div>

          <div className="carousel-item" style={{backgroundImage:`url('https://firebasestorage.googleapis.com/v0/b/edify-de3fa.appspot.com/o/crystal%2FTelecommunication.jpg?alt=media&token=f9b29e5f-1564-4712-b07e-9c0c9e3267d3')`}}>
            <div className="carousel-container">
              <div className="container">
                <h2 className="animate__animated animate__fadeInDown">Network Management and Services</h2>
                <p className="animate__animated animate__fadeInUp">We are the power behind world class civil structures and communications Systems</p>
                <Link to="/services" className="btn-get-started scrollto animate__animated animate__fadeInUp invisible">Find </Link>
              </div>
            </div>
          </div> 

        </div>

        <a className="carousel-control-prev" href="#heroCarousel" role="button" data-bs-slide="prev">
          <span className="carousel-control-prev-icon bi bi-chevron-left" aria-hidden="true"></span>
        </a>

        <a className="carousel-control-next" href="#heroCarousel" role="button" data-bs-slide="next">
          <span className="carousel-control-next-icon bi bi-chevron-right" aria-hidden="true"></span>
        </a>

      </div>
    </div>
  </section>
   {/* End Hero Section */}


   <section id="scrollspyHero" className="bsb-tpl-bg-alabaster pt-5 pb-5 p-lg-0 mt-5">
    <div className="container overflow-hidden">
      <div className="row gy-5 gy-lg-0 align-items-lg-center justify-content-lg-between">
        <div className="col-12 col-lg-6 order-1 order-lg-0">
          <h2 className=" mb-3 fw-bold">Years of Speciality in Telcom, Power Transmission and Civil Engineering works</h2>
          <p className="mb-5">The only indigenous company that have carried out construction, GSM 
Network drive test and competitors bench making solution for the entire country almost 4 folds</p>
          <div className="d-grid gap-2 d-sm-flex">
            <Link type="button" className="btn btn-primary bsb-btn-3xl" to="/contact">Contact Us</Link>
            <Link type="button" className="btn btn-outline-primary bsb-btn-3xl" to="/about">More About Us</Link>
          </div>
        </div>
        <div className="col-12 col-lg-5 text-center">
          <div className="position-relative">
            <div className="bsb-circle border border-4 border-warning position-absolute top-50 start-10 translate-middle z-1"></div>
            <div className="bsb-circle bg-primary position-absolute top-50 start-50 translate-middle" style={{"--bsb-cs": "460px;"}}></div>
            <div className="bsb-circle border border-4 border-warning position-absolute top-10 end-0 z-1" style={{"--bsb-cs": "100px;"}}></div>
            <img className="img-fluid position-relative z-2" loading="lazy" src="https://firebasestorage.googleapis.com/v0/b/edify-de3fa.appspot.com/o/crystal%2Ftele.png?alt=media&token=55a1eca7-25d9-4188-a6f3-0b746229fed3" alt="" />
          </div>
        </div>
      </div>
    </div>
  </section>


  <section id="scrollspyServices" class="bsb-service-6 py-5 py-xl-8 bsb-section-py-xxl-1">
      <div className="container">
        <div className="row justify-content-md-center">
          <div className="col-12 col-md-10 col-lg-8 col-xl-7">
            <h3 className="fs-6 mb-2 text-secondary text-center text-uppercase">What We Do?</h3>
            <h2 className="display-5 mb-4 mb-md-5 text-center">We are giving you perfect solutions with our proficient services.</h2>
            <hr className="w-50 mx-auto mb-5 mb-xl-9 border-dark-subtle" />
          </div>
        </div>
      </div>

      <div className="container overflow-hidden">
        <div className="row gy-4 gy-md-0 gx-xxl-5">
          <div className="col-12 col-md-4">
            <div className="card bg-transparent border-primary rounded-0 overflow-hidden">
              <div className="card-body">
                {/* <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-hdd text-primary mb-4" viewBox="0 0 16 16">
                  <path d="M4.5 11a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1zM3 10.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z" />
                  <path d="M16 11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V9.51c0-.418.105-.83.305-1.197l2.472-4.531A1.5 1.5 0 0 1 4.094 3h7.812a1.5 1.5 0 0 1 1.317.782l2.472 4.53c.2.368.305.78.305 1.198V11zM3.655 4.26 1.592 8.043C1.724 8.014 1.86 8 2 8h12c.14 0 .276.014.408.042L12.345 4.26a.5.5 0 0 0-.439-.26H4.094a.5.5 0 0 0-.44.26zM1 10v1a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-1a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1z" />
                </svg> */}
                <h3 className="h2 mb-4">Telecommunication</h3>
                <img src='https://firebasestorage.googleapis.com/v0/b/edify-de3fa.appspot.com/o/crystal%2Fcard2.png?alt=media&token=9d983268-618a-40df-8965-e4f9a4aafd21' className='pb-3' />
                <p className="mb-4 text-secondary">We primarily support, install and commission 
various units of microwave transmission system 
from the low capacity PDH to high capacity SDH. 
In addition, we work with equipment vendors in 
the areas of survey and planning.</p>
                <Link to="/services" className="fw-bold text-decoration-none link-primary">
                  Learn More
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right-short" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
                  </svg>
                </Link>
                <div className="bsb-circle bg-accent border border-4 border-accent position-absolute top-100 start-100 translate-middle"></div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-4">
            <div className="card bg-transparent border-primary rounded-0 overflow-hidden">
              <div className="card-body">
                {/* <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-filetype-ai text-primary mb-4" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M14 4.5V14a2 2 0 0 1-2 2H6v-1h6a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM1.113 14.82.8 15.85H0l1.342-3.999h.926l1.336 3.999h-.841l-.314-1.028H1.113Zm1.178-.588-.49-1.617h-.034l-.49 1.617h1.014Zm2.425-2.382v3.999h-.791V11.85h.79Z" />
                </svg> */}

                <h3 className="h2 mb-4">Power Engineering</h3>
                <img src='https://firebasestorage.googleapis.com/v0/b/edify-de3fa.appspot.com/o/crystal%2Fcard3.png?alt=media&token=2244b1bb-69b1-4c81-94da-b23c459debcb' className='pb-3' />
                <p className="mb-4 text-secondary">We primarily support, install and commission 
various units of microwave transmission system 
from the low capacity PDH to high capacity SDH. 
In addition, we work with equipment vendors in 
the areas of survey and planning</p>
                <Link to="/services" className="fw-bold text-decoration-none link-primary">
                  Learn More
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right-short" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
                  </svg>
                </Link>
                <div className="bsb-circle bg-accent border border-4 border-accent position-absolute top-100 start-100 translate-middle"></div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-4">
            <div className="card bg-transparent border-primary rounded-0 overflow-hidden">
              <div className="card-body">
                {/* <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-diagram-3 text-primary mb-4" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M6 3.5A1.5 1.5 0 0 1 7.5 2h1A1.5 1.5 0 0 1 10 3.5v1A1.5 1.5 0 0 1 8.5 6v1H14a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-1 0V8h-5v.5a.5.5 0 0 1-1 0V8h-5v.5a.5.5 0 0 1-1 0v-1A.5.5 0 0 1 2 7h5.5V6A1.5 1.5 0 0 1 6 4.5v-1zM8.5 5a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1zM0 11.5A1.5 1.5 0 0 1 1.5 10h1A1.5 1.5 0 0 1 4 11.5v1A1.5 1.5 0 0 1 2.5 14h-1A1.5 1.5 0 0 1 0 12.5v-1zm1.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1zm4.5.5A1.5 1.5 0 0 1 7.5 10h1a1.5 1.5 0 0 1 1.5 1.5v1A1.5 1.5 0 0 1 8.5 14h-1A1.5 1.5 0 0 1 6 12.5v-1zm1.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1zm4.5.5a1.5 1.5 0 0 1 1.5-1.5h1a1.5 1.5 0 0 1 1.5 1.5v1a1.5 1.5 0 0 1-1.5 1.5h-1a1.5 1.5 0 0 1-1.5-1.5v-1zm1.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1z" />
                </svg> */}
                <h3 className="h2 mb-4">Construction</h3>
                <img src='https://firebasestorage.googleapis.com/v0/b/edify-de3fa.appspot.com/o/crystal%2Fcard1.png?alt=media&token=d18c717c-4adb-4b9f-8d29-07d9f4c153fa' className='pb-3' />
                <p className="mb-4 text-secondary">We support your site constructions and 
integrations, Including tower erections, 
equipment shelter, BTS installation Our 
engineers and techniques are experienced with 
Greenfield or swap out operations that will save 
your net work Minimal down time</p>
                <Link to="/services" className="fw-bold text-decoration-none link-primary">
                  Learn More
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right-short" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
                  </svg>
                </Link>
                <div className="bsb-circle bg-accent border border-4 border-accent position-absolute top-100 start-100 translate-middle"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>


    <section id="scrollspyAbout" class="py-5 py-xl-8 bsb-section-py-xxl-1">
      <div class="container">
        <div class="row justify-content-md-center">
          <div class="col-12 col-md-10 col-lg-8 col-xl-7">
            <h3 class="fs-6 mb-2 text-secondary text-center text-uppercase">Our Strategy</h3>
            <h2 class=" mb-4 mb-md-5 text-center">We work on ideas and products that are not only help our clients today, but also to position them for future challenges..</h2>
            <hr class="w-50 mx-auto mb-5 mb-xl-9 border-dark-subtle" />
          </div>
        </div>
      </div>

      <div class="container">
        <div class="row gy-5 gy-lg-0 align-items-lg-center">
          <div class="col-12 col-lg-6">
            <img class="img-fluid rounded" loading="lazy" src="https://firebasestorage.googleapis.com/v0/b/edify-de3fa.appspot.com/o/crystal%2FConstructionhd.jpg?alt=media&token=3e685213-1a8a-42a0-a230-e9221330d1bd" alt="" />
          </div>
          <div class="col-12 col-lg-6">
            <div class="row justify-content-xl-end">
              <div class="col-12 col-xl-11">
                <div class="d-flex mb-4">
                  <div>
                    <span class="btn btn-primary bsb-btn-circle bsb-btn-circle-lg pe-none me-4 bsb-tpl-font fs-5">1</span>
                  </div>
                  <div>
                    <h4 class="h2 mb-4">Transmission and Distribution</h4>
                    <p class="mb-0 text-secondary">Transmission towers supply, Supply of Galvanized transmission line towers, Supply of Galvanized substation structures</p>
                  </div>
                </div>
                <div class="d-flex mb-4">
                  <div>
                    <span class="btn btn-primary bsb-btn-circle bsb-btn-circle-lg pe-none me-4 bsb-tpl-font fs-5">2</span>
                  </div>
                  <div>
                    <h4 class="h2 mb-4">Transmission Line Construction</h4>
                    <p class="mb-0 text-secondary">Survey, profile plotting and tower making, Civil construction, erection, testing and commissioning of substations-LT/HT/EHT</p>
                  </div>
                </div>
                <div class="d-flex mb-0">
                  <div>
                    <span class="btn btn-primary bsb-btn-circle bsb-btn-circle-lg pe-none me-4 bsb-tpl-font fs-5">3</span>
                  </div>
                  <div>
                    <h4 class="h2 mb-4">Distribution Systems</h4>
                    <p class="mb-0 text-secondary">Build, testing and commissioning of power distribution Systems and rural electrification Civil works construction of buildings, roads, bridges Distribution line</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>



   {/* <!-- Client 2 - Bootstrap Brain Component --> */}
<section class="py-3 py-md-5 py-xl-8">
  <div class="container">
    <div class="row justify-content-md-center">
      <div class="col-12 col-md-10 col-lg-8 col-xl-7 col-xxl-6">
        <h2 class="fs-6 text-secondary mb-2 text-uppercase text-center">Our Clients</h2>
        <h2 class="mb-4 display-5 text-center">Trusted all over East Africa</h2>
        <p class="fs-5 text-secondary mb-5 text-center">Our clients are our top priority, and we are committed to providing them with the highest level of service.</p>
        <hr class="w-50 mx-auto mb-5 mb-xl-9 border-dark-subtle" />
      </div>
    </div>
  </div>

  <div class="container overflow-hidden">
    <div class="row gy-4">
      <div class="col-6 col-md-4 col-xl-3 text-center">
        <div class="text-secondary bg-light px-4 px-md-6  px-lg-8 ">
        <img src='https://firebasestorage.googleapis.com/v0/b/edify-de3fa.appspot.com/o/crystal%2Fairtel.svg?alt=media&token=5384ef62-5cde-4746-8338-f4834cf5e655' xmlns="http://www.w3.org/2000/svg" width="100%" height="65" fill="currentColor" viewBox="0 0 284 65" />

        </div>
      </div>
      <div class="col-6 col-md-4 col-xl-3 text-center">
        <div class="text-secondary bg-light px-4  px-md-6  px-lg-8 ">
        <img src='https://firebasestorage.googleapis.com/v0/b/edify-de3fa.appspot.com/o/crystal%2Fericson.svg?alt=media&token=fe554c90-9e55-40a4-881c-c0d666e19528' xmlns="http://www.w3.org/2000/svg" width="100%" height="65" fill="currentColor" viewBox="0 0 284 65" />

        </div>
      </div>
      <div class="col-6 col-md-4 col-xl-3 text-center">
        <div class="text-secondary bg-light px-4  px-md-6  px-lg-8 ">
        <img src='https://firebasestorage.googleapis.com/v0/b/edify-de3fa.appspot.com/o/crystal%2Funra.svg?alt=media&token=681f9814-1ff8-4aca-aae5-22b149209fd9' xmlns="http://www.w3.org/2000/svg" width="100%" height="65" fill="currentColor" viewBox="0 0 284 65" />

        </div>
      </div>
      <div class="col-6 col-md-4 col-xl-3 text-center">
        <div class="text-secondary bg-light px-4  px-md-6  px-lg-8 ">
        <img src='https://firebasestorage.googleapis.com/v0/b/edify-de3fa.appspot.com/o/crystal%2Fhuawewi.svg?alt=media&token=3bd01728-48ca-4877-bd6a-9e09cdcb8fc4' xmlns="http://www.w3.org/2000/svg" width="100%" height="65" fill="currentColor" viewBox="0 0 284 65" />

        </div>
      </div>

      <div class="col-6 col-md-4 col-xl-3 text-center">
        <div class="text-secondary bg-light px-4 px-md-6 px-lg-8">
        <img src='https://firebasestorage.googleapis.com/v0/b/edify-de3fa.appspot.com/o/crystal%2Fmtn.svg?alt=media&token=dd076eca-9e3d-46a7-abf0-38b5b35820d5' xmlns="http://www.w3.org/2000/svg" width="100%" height="65" fill="currentColor" viewBox="0 0 284 65" />

        </div>
      </div>
      
    </div>
  </div>
</section>
    </div>
  )
}
