import { Link, Route, Routes } from "react-router-dom";
import Services from "./pages/Services";
import Home from "./pages/Home";
import Products from "./pages/Products";
import About from "./pages/About";
import Contact from "./pages/Contact";
import React, { useState } from "react";

function App() {

  const navi = React.createRef();
  const [mob, setMobile] = useState(false)
  const toggle = () => {
    console.log("Mobile Clicked");
    setMobile(!mob);
  }
  return (
    <div className="App">
  <header id="header" className="fixed-top d-flex align-items-center">
    <div className="container d-flex justify-content-between">

      <div className="logo">
        <h1><a href="index.html"><span>Crystal</span>Consult</a></h1>
         {/* Uncomment below if you prefer to use an image logo  */}
        {/* <a href="index.html"><img src="assets/img/logo.png" alt="" class="img-fluid" /></a> */}
      </div>

      <nav id="navbar" className={mob ? "navbar-mobile":"navbar"} ref={navi}>
      <i className={`bi bi-${mob ? "x":"list"} mobile-nav-toggle`} onClick={toggle}></i>
        <ul>
          <li><Link  to="/">Home</Link></li>
          <li><Link  to="/services">Services</Link></li>
          <li><Link  to="/products">Products</Link></li>
          <li><Link  to="/about">About</Link></li>
          {/* <li><Link  to="#portfolio">Portfolio</Link></li> */}
           {/* <li><a className="nav-link scrollto" href="#team">Team</a></li> */}
          {/* <li><a href="blog.html">Blog</a></li>
          <li className="dropdown"><a href="#"><span>Drop Down</span> <i className="bi bi-chevron-down"></i></a>
            <ul>
              <li><a href="#">Drop Down 1</a></li>
              <li className="dropdown"><a href="#"><span>Deep Drop Down</span> <i className="bi bi-chevron-right"></i></a>
                <ul>
                  <li><a href="#">Deep Drop Down 1</a></li>
                  <li><a href="#">Deep Drop Down 2</a></li>
                  <li><a href="#">Deep Drop Down 3</a></li>
                  <li><a href="#">Deep Drop Down 4</a></li>
                  <li><a href="#">Deep Drop Down 5</a></li>
                </ul>
              </li>
              <li><a href="#">Drop Down 2</a></li>
              <li><a href="#">Drop Down 3</a></li>
              <li><a href="#">Drop Down 4</a></li>
            </ul>
          </li> */}
          <li><Link to="/contact">Contact</Link></li>
        </ul>
        {/* <i className="bi bi-list mobile-nav-toggle navbar-toggle-icon " data-bs-toggle="collapse" data-bs-target="#navbar"></i> */}
        {/* <button className="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#navbar">
          <span className="navbar-toggler-icon"></span>
        </button> */}

        {/* <button class="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#navcol-1"><span class="visually-hidden">Toggle navigation</span><span class="navbar-toggler-icon"></span></button> */}
      </nav>

    </div>
  </header>
  <Routes>
    <Route path="/" >
      <Route index element={<Home />} />
      <Route path="/services" element={<Services />} />
      <Route path="/products" element={<Products />} />
      <Route path="/about" element={<About />} />
      <Route path="/contact" element={<Contact />} />
    </Route>
  </Routes>



   {/* ======= Footer ======= */}
  <footer>
    <div className="footer-area">
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <div className="footer-content">
              <div className="footer-head">
                <div className="footer-logo">
                  <h2><span>Crystal</span>Consult</h2>
                </div>

                <p>“An efficient Telecommunications network is the foundation upon which an information society is built”
<br /><strong>We make sure that it ever happens.</strong></p>
                <div className="footer-icons">
                  <ul>
                    <li>
                      <a href="#"><i className="bi bi-facebook"></i></a>
                    </li>
                    <li>
                      <a href="#"><i className="bi bi-twitter"></i></a>
                    </li>
                    <li>
                      <a href="#"><i className="bi bi-instagram"></i></a>
                    </li>
                    <li>
                      <a href="#"><i className="bi bi-linkedin"></i></a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
           {/* end single footer  */}
          <div className="col-md-4">
            <div className="footer-content">
              <div className="footer-head">
                <h4>information</h4>
                <p>
                We are synonymous with innovation, building
excellence and superior technical performance;
and advancement in technology.
                </p>
                <div className="footer-contacts">
                  <p><span>Tel:</span> +256 4142 71179</p>
                  <p><span>Email:</span> sales@crystalconsultgroup.com</p>
                  <p><span>Working Hours:</span> 9am-5pm</p>
                </div>
              </div>
            </div>
          </div>
           {/* end single footer */}
          <div className="col-md-4">
            <div className="footer-content">
              <div className="footer-head">
                <h4>Projects</h4>
                <div className="flicker-img">
                  <a href="#"><img src="https://firebasestorage.googleapis.com/v0/b/edify-de3fa.appspot.com/o/crystal%2Fcard1.png?alt=media&token=d18c717c-4adb-4b9f-8d29-07d9f4c153fa" alt="" /></a>
                  <a href="#"><img src="https://firebasestorage.googleapis.com/v0/b/edify-de3fa.appspot.com/o/crystal%2Fconstruction1.png?alt=media&token=d3233b93-e47a-409f-a132-ec20e5c29c55" alt="" /></a>
                  <a href="#"><img src="https://firebasestorage.googleapis.com/v0/b/edify-de3fa.appspot.com/o/crystal%2Fconstruction2.png?alt=media&token=5dbad4ee-829a-46d3-8713-4b769ae9366c" alt="" /></a>
                  <a href="#"><img src="https://firebasestorage.googleapis.com/v0/b/edify-de3fa.appspot.com/o/crystal%2Fcard2.png?alt=media&token=9d983268-618a-40df-8965-e4f9a4aafd21" alt="" /></a>
                  <a href="#"><img src="https://firebasestorage.googleapis.com/v0/b/edify-de3fa.appspot.com/o/crystal%2Fcard3.png?alt=media&token=2244b1bb-69b1-4c81-94da-b23c459debcb" alt="" /></a>
                  <a href="#"><img src="https://firebasestorage.googleapis.com/v0/b/edify-de3fa.appspot.com/o/crystal%2FRoad2.png?alt=media&token=8543688f-496e-4063-a923-9623031f97a4" alt="" /></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="footer-area-bottom">
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-sm-12 col-xs-12">
            <div className="copyright text-center">
              <p>
                &copy; Copyright <strong>Crystal Consult</strong>. All Rights Reserved
              </p>
            </div>
            <div className="credits">
              
              {/* All the links in the footer should remain intact.
              You can delete the links only if you purchased the pro version.
              Licensing information: https://bootstrapmade.com/license/
              Purchase the pro version with working PHP/AJAX contact form: https://bootstrapmade.com/buy/?theme=eBusiness
             */}
              Designed by <a href="http://jonestah.com">Jones & estah Systems</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
   {/* End  Footer  */}
    </div>
  );
}

export default App;
