import React from 'react'

export default function About() {
  return (
    <div>
    <div className="header-bg page-area">
<div className="container position-relative">
  <div className="row">
    <div className="col-md-12 col-sm-12 col-xs-12">
      <div className="slider-content text-center">
        <div className="header-bottom">
          <div className="layer2">
            <h1 className="title2">About Us</h1>
          </div>
          <div className="layer3">
            <h2 className="title3">Get to know more about Crystal Consults</h2>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>




<section className="py-3 py-md-5">
  <div className="container">
    <div className="row gy-3 gy-md-4 gy-lg-0 align-items-lg-center">
      <div className="col-12 col-lg-6 col-xl-5">
        <img className="img-fluid rounded" loading="lazy" src="https://firebasestorage.googleapis.com/v0/b/edify-de3fa.appspot.com/o/crystal%2FCrystalProfile.png?alt=media&token=aac62f0d-9920-45a2-a663-c7db223f0b74" alt="About 1" />
      </div>
      <div className="col-12 col-lg-6 col-xl-7">
        <div className="row justify-content-xl-center">
          <div className="col-12 col-xl-11">
            <h2 className="mb-3">Who Are We?</h2>
            <p className="leclassNamead fs-4 text-secondary mb-3">We are a compact team driven logical solutions provider to 
increasing challenges of the ever dynamic ,Civil, 
ICT, Industrial & power world.</p>
            <p className="mb-5">With our fast growth in diverse industries viz. 
Telecom, transmission and distribution, civil 
special structures and international business 
operations it is moving in the right direction to 
capture the global market.</p>
            <div className="row gy-4 gy-md-0 gx-xxl-5X">
              <div className="col-12 col-md-6">
                <div className="d-flex">
                  <div className="me-4 text-primary">
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-gear-fill" viewBox="0 0 16 16">
                      <path d="M9.405 1.05c-.413-1.4-2.397-1.4-2.81 0l-.1.34a1.464 1.464 0 0 1-2.105.872l-.31-.17c-1.283-.698-2.686.705-1.987 1.987l.169.311c.446.82.023 1.841-.872 2.105l-.34.1c-1.4.413-1.4 2.397 0 2.81l.34.1a1.464 1.464 0 0 1 .872 2.105l-.17.31c-.698 1.283.705 2.686 1.987 1.987l.311-.169a1.464 1.464 0 0 1 2.105.872l.1.34c.413 1.4 2.397 1.4 2.81 0l.1-.34a1.464 1.464 0 0 1 2.105-.872l.31.17c1.283.698 2.686-.705 1.987-1.987l-.169-.311a1.464 1.464 0 0 1 .872-2.105l.34-.1c1.4-.413 1.4-2.397 0-2.81l-.34-.1a1.464 1.464 0 0 1-.872-2.105l.17-.31c.698-1.283-.705-2.686-1.987-1.987l-.311.169a1.464 1.464 0 0 1-2.105-.872l-.1-.34zM8 10.93a2.929 2.929 0 1 1 0-5.86 2.929 2.929 0 0 1 0 5.858z" />
                    </svg>
                  </div>
                  <div>
                    <h2 className="h4 mb-3">Our Vision</h2>
                    <p className="text-secondary mb-0">To be the best at all 
times by adding value to all stake holders and 
making premium quality CONSTRUCTION, ICT, 
IDUSTRIAL & POWER services our watchword.</p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="d-flex">
                  <div className="me-4 text-primary">
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-fire" viewBox="0 0 16 16">
                      <path d="M8 16c3.314 0 6-2 6-5.5 0-1.5-.5-4-2.5-6 .25 1.5-1.25 2-1.25 2C11 4 9 .5 6 0c.357 2 .5 4-2 6-1.25 1-2 2.729-2 4.5C2 14 4.686 16 8 16Zm0-1c-1.657 0-3-1-3-2.75 0-.75.25-2 1.25-3C6.125 10 7 10.5 7 10.5c-.375-1.25.5-3.25 2-3.5-.179 1-.25 2 1 3 .625.5 1 1.364 1 2.25C11 14 9.657 15 8 15Z" />
                    </svg>
                  </div>
                  <div>
                    <h2 className="h4 mb-3">Mission Statement</h2>
                    <p className="text-secondary mb-0">We are synonymous with innovation, building
excellence and superior technical performance;
and advancement in technology.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

{/* <!-- About 5 - Bootstrap Brain Component --> */}
<section class="py-3 py-md-5 py-xl-8">
  <div class="container">
    <div class="row">
      <div class="col-12 ">
        <h3 class="fs-5 mb-2 text-secondary text-uppercase">More About Us</h3>
        <h2 class="display-5 mb-4">Our project teams operate as a 
customer controlled unit, giving top priority to 
customer needs
</h2>
        <button type="button" class="btn btn-lg btn-primary mb-3 mb-md-4 mb-xl-5 d-none">Discover More</button>
      </div>
    </div>
  </div>

  <div class="container overflow-hidden">
    <div class="row gy-4 gy-lg-0">
      <div class="col-12 col-lg-6">
        <article>
          <div class="card border-0">
            <img class="card-img-top img-fluid m-0" loading="lazy" src="https://firebasestorage.googleapis.com/v0/b/edify-de3fa.appspot.com/o/crystal%2Ftowers.png?alt=media&token=37ff09cb-aff7-4d4f-b458-b201797e878b" alt="Our Vision" />
            <div class="card-body border bg-white p-4">
              <div class="entry-header mb-3">
                <h2 class="card-title entry-title h4 mb-0">
                  <a class="link-dark text-decoration-none" href="#!">Our Concept</a>
                </h2>
              </div>
              <p class="card-text entry-summary text-secondary mb-3">We have an obligation to our clients to proactively 
identify potential and proffer solution on how to 
mitigate them. We respond to the ever increasing 
needs of our clients bearing in mind that the 
construction and telecoms sectors are very 
competitive industries.</p>
            </div>
          </div>
        </article>
      </div>
      <div class="col-12 col-lg-6">
        <article>
          <div class="card border-0">
            <img class="card-img-top img-fluid m-0" loading="lazy" src="https://firebasestorage.googleapis.com/v0/b/edify-de3fa.appspot.com/o/crystal%2Fapproach.png?alt=media&token=36080b00-50df-41d0-9162-29e1607b415a" alt="Our Approach" />
            <div class="card-body border bg-white p-4">
              <div class="entry-header mb-3">
                <h2 class="card-title entry-title h4 mb-0">
                  <a class="link-dark text-decoration-none" href="#!">Our Approach</a>
                </h2>
              </div>
              <p class="card-text entry-summary text-secondary mb-3">We work on ideas and products that are not only 
help our clients today, but also to position them
for future challenges. <br /><br />
We constantly provide innovation based research 
and development</p>
            </div>
          </div>
        </article>
      </div>
    </div>
  </div>
</section>
</div>
  )
}
